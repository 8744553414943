<template>
  <div
    class="h-full w-16 bg-white dark:bg-slate-900 border-r border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0 flex justify-between flex-col"
  >
    <div class="flex flex-col items-center">
      <logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-4 mb-10"
      />
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <a
        v-if="showAnnouncement"
        v-tooltip.right="`Show announcement`"
        class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
        @click="openAnnouncementModal"
      >
        <fluent-icon
          icon="announcement"
        />
        <span class="sr-only">Show announcement</span>
      </a>
      <primary-nav-item
        icon="book-open-globe"
        name="DOCS"
        :open-in-new-page="true"
        :to="helpDocsURL"
      />
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
    <announcement-modal ref="announcementModal" :announcement="announcement" :show-announcement="showAnnouncement" :show-button="true"/>
  </div>
</template>
<script>
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import AnnouncementModal from 'shared/components/AnnouncementModal.vue';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    AnnouncementModal,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
    showAnnouncement: {
      type: Boolean,
      default: false
    },
    announcement: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showAnnouncementModal: false
    };
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    closeAnnouncementModal() {
      this.showAnnouncementModal = false
    },
    openAnnouncementModal() {
      this.$refs.announcementModal.showAnnouncementModal = true
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 365);
      const expiryString = expiryDate.toUTCString();
      const announcementID = this.getCookieValue('lastClickedAnnouncementID')
      if((announcementID == undefined || announcementID < this.announcement.id) && this.announcement.id != null)
        document.cookie = "lastClickedAnnouncementID=" + this.announcement.id + ";path=/;expires=" + expiryString;
    },
    parsedDescription(string) {
      return string.replace(/\r\n/g, '<br>')
    },
    getCookieValue(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }
  },
  watch: {
    announcement() {
      if(this.announcement == null) return;
      const cookie = this.getCookieValue('lastClickedAnnouncementID')
      if(cookie == undefined || cookie < this.announcement.id || this.announcement.id === null) {
        this.openAnnouncementModal()
      }
    }
  }
};
</script>
